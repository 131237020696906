$(function() {

  var quickSearch = new Bloodhound({
      datumTokenizer: function (d) { return Bloodhound.tokenizers.whitespace(d.name); },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      limit: 20,
      remote: {
        url:      '/keres',
        prepare: function (query, settings) {
          settings.type = "POST";
          settings.data = { s: query };
          return settings;
        }
      }
    }),
    dealSearch = new Bloodhound({
      datumTokenizer: function (d) { return Bloodhound.tokenizers.whitespace(d.name); },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      limit: 20,
      remote: {
        url:      '/keres',
        prepare: function (query, settings) {
          settings.type = "POST";
          settings.data = { s: query,deal:true };
          return settings;
        }
      }
    }),

  $quickSearch = $('#quick-search');
  $quickSearch.typeahead({
    highlight: true,
    minLength: 2,
    hint:      false
  }, {
    name:     'quick-search',
    source:   quickSearch,
    limit:    20,
    display: function(data) {
      return data.name;
    },
    templates: {
      empty: function(data) {
        return '<h2 class="text-center notfound"><i class="fa fa-exclamation-triangle"></i> '+settings.getMeta('noresult')+'</h2>';
      },
      suggestion: function(data) {
        return '<div class="qs-container">' +
          "<h3>" +data.name+"</h3>" +
          "<span>("+data.postalcode_city+")</span>" +
        '</div>';
      }
    }
  }, {
    name:     'quick-search',
    source:   dealSearch,
    limit:    20,
    display: function(data) {
      return data.name;
    },
    templates: {
      suggestion: function(data) {
        return '<div class="qs-container deal">' +
          "<h3>" +data.name+"</h3>" +
          "<span>("+data.expiration_date+")</span>" +
        '</div>';
      }
    }
  });

  $('#quick-search').closest( 'form' ).on( 'submit keyup', function(e) {
    e.preventDefault();

    if ( e.which == 13 ) {
      $(".qs-container:first-child", this).trigger('click');
    }
  });

  $quickSearch.on('typeahead:selected', function (e,data) {
    window.open(data.full_url);
  });

  $('.btn.search.icon-search').click(function(event) {
    $("input#quick-search").focus();
  });
});
