//https://laracasts.com/series/laravel-5-and-the-front-end/episodes/8
$(function() {

  $('.show-field').on('click', '.button.show', function(e) {
    var $th = $(this), data = $th.data() || {}, $el = $th.closest('.show-field');
    //partner = _.toInteger(_.toString(data.url).replace(/^.*\/p\/(\d+).*$/,"$1")),

    $.post(data.url, data, function(resp) {
      gaEvent('ShowPartnerContacts', resp.partner);
      $el.html(initContentFields(resp.html));
      _.delay(function(){
        $el.find('.full').addClass('recall');
      },500);
    });
  }).on('click', '.recall .button', function(e) {
    var $th  = $(this),
        $el  = $th.closest('.show-field'),
        transname  = _.split(settings.getMeta('guestname'), '|'),
        data = form2js($el[0], '.', false);
      console.log(data);
      if(_.has(data, 'guestname') && !_.toString(data.guestname)) 
        return swal(_.first(transname), _.last(transname), 'error');
      
      if(!_.toInteger(data.guestphone))
        return swal(_.first(transname), settings.getMeta('guestphone'), 'error');
      
    $.post(data.url, data, function(resp) {
      gaEvent('PartnerPoked', resp.partner);
      $el.html(initContentFields(resp.html));
    });
  });

  // ------------------------------------

  $('#close-feedback-float').on('click', function(event) {
    $xhr = $.post('/feedback/closefloat', { close: true }, function( result ) {

      gaEvent('CloseFeedbackFloat', null);

      if(_.has(result,'error'))
        return swal('Hiba', result.error, 'error');
    });
  });

  // ------------------------------------

  $('.partner-hide').on('click', function(event) {
    var
      $th     = $(this),
      $url    = '/p/' + $th.data('id') + "/hide",
      $venue  = $('#venue-' + $th.data('id'));

    $xhr = $.post($url, { add: true }, function( result ) {

      gaEvent('HidePartnerFromList', $th.data('id'));

      if(_.has(result,'error'))
        return swal('Hiba', result.error, 'error');
      $venue.fadeOut(250);
    });
  });

  // ------------------------------------

  $('.partner-details-hide').on('click', function(event) {
    var
      $th     = $(this),
      $url    = '/p/' + $th.data('id') + "/hide",
      $venue  = $('#venue-' + $th.data('id'));

    gaEvent('HidePartnerDetails', $th.data('id'));

    $('.venue_wrapper',$venue).slideToggle(500);
    var status = $('i', this).toggleClass('fa-chevron-up fa-chevron-down').hasClass('fa-chevron-down');
    $('span', this).text(status ? 'mutat ': 'elrejt ');
  });

  // ------------------------------------
  //add-rfq
  $('.rfq-cart-container').on('click', '.changecart:not(.disabled)', function(e) {
    var $th = $(this).addClass('disabled'),
         data = urlToData($th.data('action'), 'save');

    $.post(data.url, data, function(result) {
      gaEvent(data.gaEvent, data.pid);

      setCartCount(result.counts, result.catname);
      flyToElement($th, $('#selected_venue span'));
      $('#panel-'+result.catname+' .rfq-cart-list').append(result.view);
      if (settings.holderTabs instanceof Foundation.Tabs)
        settings.holderTabs.selectTab('panel-'+result.catname);

    }).always(function() {
      $th.removeClass('disabled');
    });
  });

  // ------------------------------------

  $('#send-rfq').on('click', '.changecart:not(.disabled)', function(e) {
    var $th = $(this).addClass('disabled'),
        $el  = $th.closest('.cart-item'),
        data = urlToData(e.currentTarget.dataset.action, 'delete');
        $add = $('.remove-rfq[data-action="'+data.url+'"]');

    $.post(data.url, data, function(result) {
      gaEvent(data.gaEvent, data.pid);

      setCartCount(result.counts,result.catname);
      $add.html('MENTÉS A LISTÁMRA').removeClass('remove-rfq alert').addClass('changecart');

      if ((settings.holderTabs instanceof Foundation.Tabs) && !_.get(result,['counts','cart-cat-count']) && !_.isEmpty(result.activeCategory))
        settings.holderTabs.selectTab('panel-'+result.activeCategory);

      $el.fadeOut(1000, function(){
        $(this).remove();
      });

    }).fail(function() {
      $th.removeClass('disabled');
    });
  });

  // ------------------------------------

  $('tr.rfq-cart-item').on('click', '.changecart:not(.disabled)', function(e) {
    var $th = $(this).addClass('disabled'),
        $el  = $th.closest('tr.rfq-cart-item'),
        data = urlToData(e.currentTarget.dataset.action, 'delete');

    $.post(data.url, data, function(result) {
      gaEvent(data.gaEvent, data.pid);

      $el.fadeOut(1000).remove();
    }).always(function() {
      $th.removeClass('disabled');
    });
  });

  // ------------------------------------

  $( '.rfq-cart-list' ).bind('mousewheel DOMMouseScroll', function ( e ) {
    var e0 = e.originalEvent,
        delta = e0.wheelDelta || -e0.detail;

    this.scrollTop += ( delta < 0 ? 1 : -1 ) * 30;
    e.preventDefault();
  });

  $('.event-select').on('change', '.event-selector', function(e) {
    var
      data = _.extend({ id: e.currentTarget.value }),
      $evtDetails = $('.event-selector-details');

    $('.event-selector').val(e.currentTarget.value);
     $.post('/eventmanager/info', data, function(result) {
       gaEvent('EventSelect', null);
      $evtDetails.html(result.view);
    })
  });

  /* --------------------------------------------------------------------------------------------------------------------
   * Qtip2
   * http://qtip2.com
   */

  $('[sugo-content][sugo-title]').qtip({
    content: {
      text: function(event, api) {
        return $(this).attr('sugo-content');
      },
      title: {
        text: function(event, api) {
          return $(this).attr('sugo-title');
        },
        button: false
      }
    },
    hide: { leave: true, },
    style: { classes: 'qtip-light qtip-shadow' }
  });

  $('[sugo-content][sugo-notitle]').qtip({
    content: {
      text: function(event, api) {
        return $(this).attr('sugo-content');
      }
    },
    hide: { leave: true, },
    style: { classes: 'qtip-light qtip-shadow' }
  });

  // ------------------------------------

  $('.featmenu[data-href]').on('click', function(e) {
    var data = e.currentTarget.dataset;
    if('none' === $('#'+data.toggle).css('display'))
      data.href && (window.location.href = data.href);
  });

  // ------------------------------------

  $('.images_viewer').on('click', 'i[data-video]', function(e){
    $(this).closest('.swiper-slide').html('<video loop autoplay controls><source src="' + e.currentTarget.dataset.video + '" type="video/mp4"></video>');
  });

  $('[data-popuptoggle]').on('click', function(e) {
    $('#'+e.currentTarget.dataset.popuptoggle).show(300);
  });

  //$('.images_viewer .swiper-slide-active video').click(function () {
  //  _.runIsFunction(document.querySelector('.images_viewer .swiper-slide-active video'),'pause');
  //});

  /* --------------------------------------------------------------------------------------------------------------------
   * Scroll events
   */

  settings.$window.on('scroll', function() {
    settings.$inCache.length && setHolderSticky();
  });


});

/* --------------------------------------------------------------------------------------------------------------------
 * Functions
 */


function selected() {
  customText = 'Mindegy...';
  var secText = '';
  has = true;
  $('.customs .opt.selected').each(function(index, el) {
    if(secText) {
      secText += ', ';
    }
    secText += $(this).text();
    has = false;
  });
  if(secText)
    customText = secText;
  $('.customs .SelectBox span').html(customText);
  if(has)
    $('.customs .SelectBox span').addClass('placeholder');
  else
    $('.customs .SelectBox span').removeClass('placeholder');
}

function leavePage() {
  $.post('/feedback/leavePage');
  //$xhr = $.post('/feedback/leavePage', { close: true }, function( result ) {});
}

function getGeo(success_func, fail_func) {

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(getPosition, errors);
  } else {
    FAIL("NOT_SUPPORTED");
    return;
  }

  function getPosition(position) {
    user_lat = position.coords.latitude;
    user_long = position.coords.longitude;
    SUCCESS(user_lat, user_long);
    return;
  }

  function errors(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        FAIL("PERMISSION_DENIED");
        break;

      case error.POSITION_UNAVAILABLE:
        FAIL("POSITION_UNAVAILABLE");
        break;

      case error.TIMEOUT:
        FAIL("TIMEOUT");
        break;

      case error.UNKNOWN_ERROR:
        FAIL("UNKNOWN_ERROR");
        break;
      }
    return;
  }

  function SUCCESS(user_lat, user_long) {
    if (typeof (success_func) != "undefined") {
      success_func(user_lat, user_long);
    } else {
      //alert("Latitude = " + user_lat + " and Longitude = " + user_long);
    }
  }

  function FAIL(error) {
    if (typeof (fail_func) != "undefined") {
      fail_func(error);
    } else {
      //alert(error);
    }
  }
}


function setHolderSticky() {
  var fixedTop = settings.$window.scrollTop() > settings.$inCache.offset().top;

  settings.$cache.Class(fixedTop ? 'add' : 'remove', 'holder');
}

function gaEvent(eventAction, partnerId) {

  if(!_.has(window, 'ga'))
    return false;
  ga('send', 'event', {
    eventCategory: 'UserActivity',
    eventAction: eventAction,
    eventLabel: partnerId ? "partner: " + partnerId : ''
  });

}

  // ------------------------------------

function setCartCount(counts,category) {
  var
    $holderHeader = $('#selected_venue'),
    $tabs         = $('#category-tabs>li[data-category-name]'),
    $tab          = $('#category-tabs>li[data-category-name='+category+']'),
    $panels       = $('#category-tabs-contents .tabs-panel'),
    $panel        = $('#panel-'+category);


  _.each(counts, function(val,key) {
    if($tab[0]) {
      $('span.'+key,$tab).html(val);
      if(_.toInteger(val)) {
        $tab.removeClass('disabled');
        //$panel.css('display','block'); //.removeClass('disabled');
      } else {
        $tab.addClass('disabled').removeClass('is-active');
        $('>a',$tab).attr('aria-selected','false');
        $panel.removeClass('is-active'); //.css('display','none');
      }
    }
    $('span.'+key,$holderHeader).html(val);
  });
}

  // ------------------------------------


function flyToElement(flyer, flyingTo) {
  var
    $func       = $(this),
    divider     = 3,
    $flyer      = $(flyer).html('Elmentve').removeClass('changecart').addClass('remove-rfq'),
    flyerClone  = $flyer.clone();

  $(flyerClone).css({position: 'absolute', top: $flyer.offset().top + "px", left: $flyer.offset().left + "px", opacity: 1, 'z-index': 1000});
  $('body').append($(flyerClone));

  var
    gotoX = $(flyingTo).offset().left + ($(flyingTo).width() / 2) - ($flyer.width()/divider)/2,
    gotoY = $(flyingTo).offset().top + ($(flyingTo).height() / 2) - ($flyer.height()/divider)/2;

  $(flyerClone).animate({
    opacity: 0.4,
    left: gotoX,
    top: gotoY,
    width: $flyer.width()/divider,
    height: $flyer.height()/divider
  }, 700, function () {
    $(flyingTo).fadeOut('fast', function () {
      $(flyingTo).fadeIn('fast', function () {
        $(flyerClone).fadeOut('fast', function () {
          $(flyerClone).remove();
        });
      });
    });
  });

}

  // ------------------------------------

function makeAntiBot() {
  $('.recaptcha').each(function() {
    var sitekey = $(this).data('sitekey');

    sitekey && grecaptcha.render(this, {
      sitekey: sitekey
    });
  });
}

function urlToData(url,action) {
  var data = _.zipObject(
    [ 'ptype', 'pid', 'action', 'url', 'gaEvent' ],
    _.split(url,'/').splice(-2).concat([ action, url ])
  );
  data.gaEvent = ['MyCart-', action, data.ptype].join('-');
  return data;
}
